import {ChangeDetectionStrategy, Component, Inject, OnInit, Optional} from "@angular/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {PanelOverlay} from "@overlay-panel/panel-overlay";
import {PanelOverlayRef} from "@overlay-panel/panel-overlay-ref";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {PANEL_OVERLAY_DATA} from "@overlay-panel/panel-overlay.tokens";
import {Color} from "@utils/color-service/color.interface";
import {ColorService} from "@utils/color-service/color.service";
import {TagService} from "@tags/tag.service";
import {Tag} from "@publisher/content.interface";
import {Observable} from "rxjs";
import {IContentTag} from "../content-tag.interface";
import {IContentTagForm} from "../content-tag-form.interface";

@UntilDestroy()
@Component({
  selector: "app-create-update-content-tag",
  templateUrl: "./create-update-content-tag.component.html",
  animations: [
    trigger("slideContent", [
      state("void", style({transform: "translateX(510px)"})),
      state("enter", style({transform: "none"})),
      state("leave", style({transform: "translateX(510px)"})),
      transition("void => enter", animate("450ms cubic-bezier(.15,1,.3,1)")),
      transition("enter => leave", animate("450ms cubic-bezier(.15,1,.3,1)")),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateUpdateContentTagComponent extends PanelOverlay implements OnInit {
  form: FormGroup;
  colors: Color[];
  tags: Tag[];
  tag: Tag;
  title = "Create New Content Tag";
  submitted = false;
  loading: boolean;
  error: string;
  loadingColors = true;

  constructor(
    dialogRef: PanelOverlayRef<CreateUpdateContentTagComponent>,
    public formBuilder: FormBuilder,
    public snackbar: MatSnackBar,
    @Optional() @Inject(PANEL_OVERLAY_DATA) data: IContentTag,
    public colorService: ColorService,
    private tagService: TagService,
  ) {
    super(dialogRef, data);

    this.form = this.formBuilder.group({
      Id: "",
      Name: ["", Validators.required],
      ColorHex: ["", Validators.required],
      ColorName: ["", Validators.required],
    });

    this.tags = data?.tags || [];

    this.loadColors();
  }

  ngOnInit() {
    if (this.data && this.data.tag) {
      this.form.patchValue(this.data.tag);
      this.title = "Update Tag";
    }
  }

  private loadColors(): void {
    this.colorService
      .getColorsList()
      .then((colors) => {
        this.colors = colors;
        this.loadingColors = false;
      })
      .catch((error) => {
        console.error("Error loading colors:", error);
        this.colors = [];
        this.loadingColors = false;
      });
  }

  selectColor(color: Color) {
    this.form.get("ColorHex").setValue(color.Hex);
    this.form.get("ColorName").setValue(color.Name);
  }

  get selectedColorHex(): string {
    return (this.form.value as IContentTagForm).ColorHex;
  }

  onTagNameInput() {
    const value = this.form.get("Name").value;
    this.form.get("Name").setValue(value.toLowerCase());
  }

  save() {
    this.error = "";
    this.submitted = true;
    this.loading = true;

    if (this.form.valid) {
      const tagNameLower = this.form.value.Name.toLowerCase();
      const tagExists = this.tags.some((tag) => tag.Name.toLowerCase() === tagNameLower);
      const isEditingExistingTag = this.data?.tag && tagExists && this.data.tag.Id !== this.form.value.Id;

      if (tagExists && !isEditingExistingTag) {
        this.snackbar.open("A content tag with this name already exists.", null, {duration: 3000});
        this.error = "A tag with this name already exists.";
        this.loading = false;
        return;
      }

      let observable: Observable<Tag>;
      if (!this.data?.tag) {
        const data = this.form.value;
        delete data.Id;
        observable = this.tagService.create(data);
      } else {
        observable = this.tagService.edit(this.form.value);
      }

      observable.pipe(untilDestroyed(this)).subscribe(
        (tag: Tag) => {
          this.dialogRef.close(tag);
        },
        (error) => {
          this.error = `Error occurred while saving the tag. ${error}.`;
          this.loading = false;
        },
      );
    } else {
      this.loading = false;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
