<div class="h-full shadow-overlay-panel bg-light-base-100 dark:bg-dark-base-0" xmlns="http://www.w3.org/1999/html">
  <!-- Header -->
  <app-header [showMenuToggle]="false" [showCollapseToggle]="false">
    <app-header-left>
      <div class="text-sm font-medium text-light-base-600 dark:text-dark-base-400 ml-1.5">
        <ng-container>{{ title }}</ng-container>
      </div>
    </app-header-left>
    <app-header-right>
      <!-- Close -->
      <app-icon-button
        icon="x"
        (buttonClick)="close()"
        buttonType="button"
        iconSize="20"
        class="block -mr-1"></app-icon-button>
    </app-header-right>
  </app-header>
  <!-- Wrapper -->
  <form
    [formGroup]="form"
    class="flex flex-col min-h-[calc(100%-50px)] max-h-[calc(100%-60px)] min-w-[510px] max-w-[510px] bg-light-base-100 dark:bg-dark-base-0">
    <ng-scrollbar class="app-scrollbar flex-1 flex p-5">
      <div class="flex flex-col p-5">
        <div
          class="relative bg-light-base-0 dark:bg-dark-base-50 border border-solid border-b-2 border-light-base-300/90 dark:border-dark-base-300/90 rounded-md">
          <div
            class="sticky top-0 rounded-t-md flex items-center px-5 py-5 border-0 border-b border-solid box-border border-light-base-300 dark:border-dark-base-400/10 bg-light-base-0 dark:bg-dark-base-100">
            <div class="flex-1">
              <!-- Tag name -->
              <mat-form-field
                appearance="outline"
                [ngClass]="{'mb-2.5': submitted && form.get('Name').errors}"
                class="w-full">
                <mat-label>Tag Name</mat-label>
                <input
                  matInput
                  autofocus
                  style="text-transform: lowercase"
                  (keyup)="onTagNameInput()"
                  (keydown)="onTagNameInput()"
                  formControlName="Name"
                  data-test="settings-tags-name-input"
                  autocomplete="off" />
                <mat-error *ngIf="submitted && form.get('Name').errors" data-test="settings-tags-name-input-error">
                  <span *ngIf="form.get('Name').errors.required">Tag name is required.</span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <!-- Tag color -->
          <div *ngIf="loadingColors" class="flex items-center justify-center h-24">
            <app-loader></app-loader>
          </div>
          <div
            *ngIf="!loadingColors"
            class="colors grid grid-cols-10 gap-3 p-5"
            [ngClass]="{error: submitted && form.get('ColorHex').errors}"
            data-test="settings-tags-color-container">
            <app-tag-circle
              *ngFor="let color of colors"
              class="block hover:cursor-pointer"
              [ngClass]="{
                'ring-2 ring-offset-2	ring-light-brand-100 dark:ring-dark-brand-100': selectedColorHex === color.Hex
              }"
              (click)="selectColor(color)"
              [color]="color.Hex"
              matTooltip="{{ color.Name | lowercase }}"
              matTooltipPosition="above"></app-tag-circle>
          </div>
          <div class="color-error">
            <mat-error *ngIf="submitted && form.get('ColorHex').errors" data-test="settings-tags-color-error">
              <span *ngIf="form.get('ColorHex').errors.required">Tag color is required.</span>
            </mat-error>
          </div>
        </div>
      </div>
    </ng-scrollbar>
    <app-footer class="h-[70px] bg-light-base-100 dark:bg-dark-base-0 z-50">
      <div class="flex-1">
        <!-- Save -->
        <app-button
          type="block"
          buttonType="submit"
          (buttonClick)="save()"
          [loading]="loading"
          [disabled]="form.invalid"
          >{{ title }}</app-button
        >
      </div>
    </app-footer>
  </form>
</div>
